<template lang="pug">
  v-list-item(ghost)
    v-checkbox.mr-3(
      v-if="selectable"
      :value="isSelected"
      @input="onSelect")
    v-avatar.mr-3(
      :size="40"
      :src="item.profilePictureUrl")
    v-list-item-content
      v-list-item-title {{ item.firstName + ' ' + item.lastName }}
      v-list-item-subtitle(
        v-if="item.position")
        span {{ item.position.name }}
      v-list-item-subtitle(
        v-else-if="item.department")
        span {{ item.department.name }}
      v-list-item-subtitle(
        v-if="isSupervisor")
        span.success {{ 'ui.labels.supervisor' | translate }}
    v-list-item-actions
      v-dropdown(
        ref="dropdown"
        right
        scrollable-fixed
        auto-close
        width-auto
        @toggle="toggleDropdown")
        v-btn(
          icon
          gray)
          i.fa.fa-ellipsis-v
        template(slot="content")
          div(style="width:180px")
            v-card(elevation)
              template(v-if="allowSetAsSupervisor")
                v-list-item(
                  v-if="!isSupervisor"
                  @click="onSupervisorSet")
                  v-list-item-content
                    v-list-item-title {{ 'pages.administration.set_as_supervisor' | translate }}
                v-list-item(
                  v-else
                  @click="onSupervisorRemove")
                  v-list-item-content
                    v-list-item-title {{ 'pages.administration.remove_as_supervisor' | translate }}
              v-list-item(@click="$emit('remove', item)")
                v-list-item-content
                  v-list-item-title
                    span.danger {{ 'ui.buttons.remove' | translate }}

</template>

<script>
export default {
  name: 'EmployeesListItem',

  props: {
    position: String,
    selectable: Boolean,
    isSelected: Boolean,
    isSupervisor: Boolean,
    allowSetAsSupervisor: Boolean,
    item: Object
  },

  methods: {
    onSupervisorSet () {
      this.$emit('set-as-supervisor', this.item.id)
    },

    onSupervisorRemove () {
      this.$emit('set-as-supervisor', null)
    },

    onSelect () {
      this.$emit('select', { selected: !this.isSelected, item: this.item })
    },

    toggleDropdown (value) {
      if (value) {
        this.$emit('close-dropdown-cb', this.$refs.dropdown.closeDropdown)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
